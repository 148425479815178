import React, { useState, useRef, useEffect } from 'react';
import { ArrowLeft, ArrowRight, Building, Users, Globe, Briefcase, LandPlot } from 'lucide-react';
import Layout from '../components/Layout';
import api from '../components/api';
import PageBanner from '../components/Banner';

const LogoCarousel = ({ category }) => {
   const [isHovered, setIsHovered] = useState(false);
   const scrollRef = useRef(null);
   const icons = {
       Globe,
       Building,
       Users,
       Briefcase,
       LandPlot
   };
   const Icon = icons[category.icon] || Globe;

   const scroll = (direction) => {
       if (scrollRef.current) {
           const scrollAmount = direction === 'left' ? -400 : 400;
           scrollRef.current.scrollBy({
               left: scrollAmount,
               behavior: 'smooth'
           });
       }
   };

   useEffect(() => {
       const scrollContainer = scrollRef.current;
       let scrollInterval;

       const startScroll = () => {
           scrollInterval = setInterval(() => {
               if (!isHovered && scrollContainer) {
                   scrollContainer.scrollLeft += 1;
                   if (scrollContainer.scrollLeft >= (scrollContainer.scrollWidth - scrollContainer.clientWidth)) {
                       scrollContainer.scrollLeft = 0;
                   }
               }
           }, 20);
       };

       startScroll();
       return () => clearInterval(scrollInterval);
   }, [isHovered]);

   return (
       <div className="bg-white rounded-xl shadow-lg p-8 mt-8">
           <div className="flex items-center mb-6">
               <Icon className="w-8 h-8 text-orange-500 mr-3" />
               <h3 className="text-2xl font-bold text-gray-900">{category.nom}</h3>
           </div>

           <div className="relative" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
               <button
                   onClick={() => scroll('left')}
                   className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/90 p-2 rounded-full shadow-lg hover:bg-orange-50"
               >
                   <ArrowLeft className="w-6 h-6 text-orange-500" />
               </button>

               <div ref={scrollRef} className="flex overflow-x-hidden gap-8 py-4 px-12">
                   {[...category.partners, ...category.partners].map((partner, index) => (
                       <div
                           key={`${partner.id}-${index}`}
                           className="flex-shrink-0 w-40 h-24 bg-white rounded-lg shadow-sm border border-gray-100 flex items-center justify-center p-4 hover:shadow-md transition-shadow"
                       >
                           <img
                               src={partner.logo}
                               alt={partner.nom}
                               className="max-w-full max-h-full object-contain"
                               draggable="false"
                               style={{
                                   WebkitUserSelect: 'none',
                                   userSelect: 'none',
                                   pointerEvents: 'none'
                               }}
                           />
                       </div>
                   ))}
               </div>

               <button
                   onClick={() => scroll('right')}
                   className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/90 p-2 rounded-full shadow-lg hover:bg-orange-50"
               >
                   <ArrowRight className="w-6 h-6 text-orange-500" />
               </button>
           </div>
       </div>
   );
};

const NosPartenaires = () => {
   const [categories, setCategories] = useState([]);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
       const fetchCategories = async () => {
           try {
               const response = await api.get('/aydees/liste-partennaire/');
               setCategories(response.data);
           } catch (error) {
               console.error('Erreur:', error);
           } finally {
               setLoading(false);
           }
       };
       fetchCategories();
   }, []);

   if (loading) {
       return (
           <Layout>
               <div className="min-h-screen flex items-center justify-center">
                   <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500"></div>
               </div>
           </Layout>
       );
   }

   return (
       <Layout>
           <PageBanner
                title="Nos Partenaires"
                subtitle="Un écosystème d'excellence pour votre réussite"
                />
           <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
               <div className="text-center max-w-3xl mx-auto mb-16">
                   <h2 className="text-3xl font-bold text-gray-900 mb-4">Un Réseau d'Excellence</h2>
                   <p className="text-gray-600">
                       Nous collaborons avec les meilleurs acteurs du secteur pour vous offrir des solutions innovantes et performantes.
                   </p>
               </div>
               {categories.map((category) => (
                   <LogoCarousel key={category.id} category={category} />
               ))}
           </div>
       </Layout>
   );
};

export default NosPartenaires;