import React from 'react';


const PageBanner = ({ title, subtitle }) => (
  <div className="relative bg-gradient-to-r from-gray-900 to-gray-800 overflow-hidden">
    <div className="absolute inset-0">
      <div className="absolute inset-0 bg-[linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341),linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341)] bg-[length:60px_60px] bg-[position:0_0,30px_30px] opacity-20"></div>
      <div className="absolute inset-0 bg-gradient-to-r from-orange-500/20 via-orange-500/10 to-orange-500/20 animate-gradient-x"></div>
      <div className="absolute top-0 right-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl"></div>
      <div className="absolute bottom-0 left-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl"></div>
    </div>

    <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <div className="flex items-center justify-center space-x-2 mb-6">
          <div className="h-1 w-12 bg-orange-400 rounded-full"></div>
          <div className="h-1 w-24 bg-orange-500 rounded-full"></div>
          <div className="h-1 w-12 bg-orange-400 rounded-full"></div>
        </div>
        <h1 className="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl">
          {title}
        </h1>
        {subtitle && (
          <p className="mt-4 text-xl text-orange-300 font-medium">
            {subtitle}
          </p>
        )}
      </div>
    </div>
  </div>
);

export default PageBanner