import React, { useEffect, useRef, useState } from 'react';
import api from './api';

const ServiceCard = ({ service }) => (
 <div className="min-w-[300px] sm:min-w-[350px] h-[420px] p-6 bg-white rounded-2xl shadow-md mx-4 flex flex-col">
   <div className="h-[180px] w-full rounded-xl overflow-hidden">
     <img 
       src={service.image} 
       alt={service.titre} 
       className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-300"
     />
   </div>
   
   <div className="flex flex-col h-[190px]">
     <h3 className="text-xl font-bold mt-4 line-clamp-2 h-[56px]">{service.titre}</h3>
     <p className="text-gray-600 line-clamp-3 h-[72px] text-sm">{service.description}</p>
     <div className="mt-auto pt-4">
       <a 
         href={service.link} 
         className="inline-flex items-center text-orange-500 hover:text-orange-600 font-medium group"
       >
         En Savoir
         <svg 
           className="w-4 h-4 ml-2 transform transition-transform group-hover:translate-x-1" 
           fill="none" viewBox="0 0 24 24" stroke="currentColor"
         >
           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
         </svg>
       </a>
     </div>
   </div>
 </div>
);

const ServicesSection = () => {
 const scrollRef = useRef(null);
 const [isMouseOver, setIsMouseOver] = useState(false);
 const [services, setServices] = useState([]);
 const [loading, setLoading] = useState(true);

 useEffect(() => {
   const fetchServices = async () => {
     try {
       const response = await api.get('/aydees/liste-service/');
       setServices(response.data);
     } catch (error) {
       console.error('Erreur:', error);
     } finally {
       setLoading(false);
     }
   };
   fetchServices();
 }, []);

 useEffect(() => {
   const scrollContainer = scrollRef.current;
   let animationFrameId;
   let scrollPosition = 0;
   const scrollSpeed = 0.5;

   const scroll = () => {
     if (!isMouseOver && scrollContainer) {
       scrollPosition += scrollSpeed;
       if (scrollPosition >= scrollContainer.scrollWidth / 2) {
         scrollPosition = 0;
         scrollContainer.scrollLeft = 0;
       }
       scrollContainer.scrollLeft = scrollPosition;
       animationFrameId = requestAnimationFrame(scroll);
     }
   };

   animationFrameId = requestAnimationFrame(scroll);
   return () => {
     if (animationFrameId) {
       cancelAnimationFrame(animationFrameId);
     }
   };
 }, [isMouseOver]);

 if (loading) {
   return (
     <div className="py-20 bg-gray-50 flex justify-center items-center min-h-[500px]">
       <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500"></div>
     </div>
   );
 }

 if (!services || services.length === 0) return null;

 return (
   <section className="py-20 bg-gray-50">
     <div className="max-w-7xl mx-auto px-4">
       <div className="text-center mb-12">
         <p className="text-sm font-semibold text-gray-500 uppercase tracking-wide mb-2">
           NOTRE FORCE, LA SYNERGIE DE NOS EXPERTISES
         </p>
         <h2 className="text-3xl md:text-4xl font-bold mb-4">
           Nos services
         </h2>
         <div className="h-1 w-20 bg-orange-500 mx-auto rounded-full"></div>
       </div>

       <div className="relative">
         <div className="absolute inset-y-0 left-0 w-20 bg-gradient-to-r from-gray-50 to-transparent z-10" />
         <div className="absolute inset-y-0 right-0 w-20 bg-gradient-to-l from-gray-50 to-transparent z-10" />
         
         <div 
           ref={scrollRef}
           className="overflow-hidden"
           onMouseEnter={() => setIsMouseOver(true)}
           onMouseLeave={() => setIsMouseOver(false)}
         >
           <div className="flex whitespace-nowrap py-4">
             {[...services, ...services].map((service, index) => (
               <div key={`${service.id}-${index}`} className="inline-block whitespace-normal">
                 <ServiceCard service={service} />
               </div>
             ))}
           </div>
         </div>
       </div>

       <div className="text-center mt-12">
         <a 
           href="#"
           className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 transition-colors duration-300"
         >
           Découvrir tous nos services
         </a>
       </div>
     </div>
   </section>
 );
};

export default ServicesSection;