import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import api from '../components/api';
import { Calendar, Clock, Share2, Facebook, Twitter, Linkedin, MessageCircle, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';


const FormationDetail = () => {
    const { id } = useParams();
    const [formation, setFormation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showShareMenu, setShowShareMenu] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchFormation = async () => {
            try {
                const response = await api.get(`/aydees/formation-detail/${id}/`);
                setFormation(response.data);
            } catch (error) {
                console.error('Erreur:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchFormation();
    }, [id]);

    const calculateDuration = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffTime = Math.abs(end - start);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const shareUrls = formation ? {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
        twitter: `https://twitter.com/intent/tweet?url=${window.location.href}&text=${formation.titre}`,
        linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${formation.titre}`,
        whatsapp: `https://wa.me/?text=${formation.titre} ${window.location.href}`
    } : {};

    const handleShare = (platform) => {
        window.open(shareUrls[platform], '_blank', 'width=600,height=400');
    };

    if (loading) {
        return (
            <Layout>
                <div className="min-h-screen flex items-center justify-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500"></div>
                </div>
            </Layout>
        );
    }

    if (!formation) {
        return (
            <Layout>
                <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                    <h2 className="text-2xl font-bold text-gray-900">
                        Formation non trouvée
                    </h2>
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="bg-gray-50 pt-24 pb-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
                        {/* Main Content */}
                        <div className="lg:col-span-2 space-y-8">
                            {/* Title Section */}
                            <div className="bg-white rounded-lg shadow p-6">
                                <h1 className="text-3xl font-bold text-gray-900 mb-6">
                                    {formation.titre}
                                </h1>
                                
                                <div className="flex flex-wrap gap-6 text-sm text-gray-600">
                                    <div className="flex items-center">
                                        <Calendar className="w-5 h-5 mr-2 text-orange-500" />
                                        <span>
                                            Du {new Date(formation.date_debut).toLocaleDateString()} 
                                            au {new Date(formation.date_fin).toLocaleDateString()}
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <Clock className="w-5 h-5 mr-2 text-orange-500" />
                                        <span>{calculateDuration(formation.date_debut, formation.date_fin)} jours</span>
                                    </div>
                                    <div className="flex items-center">
                                        <Users className="w-5 h-5 mr-2 text-orange-500" />
                                        <span>{formation.places_disponibles} places disponibles</span>
                                    </div>
                                </div>
                            </div>

                            {/* Image Section avec ratio 16:9 */}
                            <div className="bg-white rounded-lg shadow overflow-hidden">
                                <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
                                    <img
                                        src={formation.image}
                                        alt={formation.titre}
                                        className="absolute top-0 left-0 w-full h-full object-cover"
                                    />
                                </div>
                            </div>

                            {/* Description Section */}
                            <div className="bg-white rounded-lg shadow p-8">
                                <article className="prose max-w-none">
                                    <div 
                                        className="richtext-content"
                                        dangerouslySetInnerHTML={{ __html: formation.description }} 
                                    />
                                </article>
                            </div>
                        </div>

                        {/* Sidebar */}
                        <div className="lg:col-span-1">
                            <div className="bg-white rounded-lg shadow p-6 sticky top-8">
                                <div className="mb-6">
                                    <span className="text-3xl font-bold text-orange-500">
                                        {Number(formation.prix).toLocaleString()} FCFA
                                    </span>
                                </div>

                                <button className="w-full bg-orange-500 text-white py-3 px-6 rounded-lg hover:bg-orange-600 transition-colors mb-4"
                                         onClick={() => navigate(`/inscription/${formation.id}/${formation.titre.toLowerCase().replace(/\s+/g, '-')}`)}
                                >
                                    S'inscrire à la formation
                                </button>

                                <div className="relative">
                                    <button 
                                        className="w-full flex items-center justify-center gap-2 border-2 border-orange-500 text-orange-500 py-3 px-6 rounded-lg hover:bg-orange-50 transition-colors"
                                        onClick={() => setShowShareMenu(!showShareMenu)}
                                    >
                                        <Share2 className="w-5 h-5" />
                                        Partager
                                    </button>

                                    {showShareMenu && (
                                        <div className="absolute left-0 right-0 mt-2 bg-white rounded-lg shadow-lg py-2 border z-50">
                                            <button 
                                                onClick={() => handleShare('facebook')}
                                                className="w-full flex items-center px-4 py-2 hover:bg-gray-100"
                                            >
                                                <Facebook className="w-5 h-5 mr-3 text-blue-600" />
                                                Facebook
                                            </button>
                                            <button 
                                                onClick={() => handleShare('twitter')}
                                                className="w-full flex items-center px-4 py-2 hover:bg-gray-100"
                                            >
                                                <Twitter className="w-5 h-5 mr-3 text-blue-400" />
                                                Twitter
                                            </button>
                                            <button 
                                                onClick={() => handleShare('linkedin')}
                                                className="w-full flex items-center px-4 py-2 hover:bg-gray-100"
                                            >
                                                <Linkedin className="w-5 h-5 mr-3 text-blue-700" />
                                                LinkedIn
                                            </button>
                                            <button 
                                                onClick={() => handleShare('whatsapp')}
                                                className="w-full flex items-center px-4 py-2 hover:bg-gray-100"
                                            >
                                                <MessageCircle className="w-5 h-5 mr-3 text-green-500" />
                                                WhatsApp
                                            </button>
                                        </div>
                                    )}
                                </div>

                                <div className="mt-6 space-y-4">
                                    <h4 className="font-bold text-gray-900">Informations importantes</h4>
                                    <div className="text-sm space-y-2">
                                        <p className="flex justify-between">
                                            <span className="text-gray-600">Places disponibles:</span>
                                            <span className="font-medium">{formation.places_disponibles}</span>
                                        </p>
                                        <p className="flex justify-between">
                                            <span className="text-gray-600">Durée:</span>
                                            <span className="font-medium">
                                                {calculateDuration(formation.date_debut, formation.date_fin)} jours
                                            </span>
                                        </p>
                                        <p className="flex justify-between">
                                            <span className="text-gray-600">Date de début:</span>
                                            <span className="font-medium">
                                                {new Date(formation.date_debut).toLocaleDateString()}
                                            </span>
                                        </p>
                                        <p className="flex justify-between">
                                            <span className="text-gray-600">Date de fin:</span>
                                            <span className="font-medium">
                                                {new Date(formation.date_fin).toLocaleDateString()}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default FormationDetail;