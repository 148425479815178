import React, { useState, useEffect } from 'react';
import { Linkedin, Twitter } from 'lucide-react';
import Layout from '../components/Layout';
import api from '../components/api';
import PageBanner from '../components/Banner';


const TeamMember = ({ member }) => {
  return (
    <div className="group cursor-pointer">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl">
        <div className="relative h-[400px] overflow-hidden">
          <img
            src={member.image}
            alt={member.nom_prenom}
            className="w-full h-full object-cover object-center"
            loading="lazy"
          />

          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/60 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

          <div className="absolute bottom-28 left-0 w-full flex justify-center space-x-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <a
              href={member.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="p-3 bg-white rounded-full hover:bg-gray-100 transition-colors duration-300"
            >
              <Linkedin className="w-5 h-5 text-gray-700" />
            </a>
            <a
              href={member.twitter}
              target="_blank"
              rel="noopener noreferrer"
              className="p-3 bg-white rounded-full hover:bg-gray-100 transition-colors duration-300"
            >
              <Twitter className="w-5 h-5 text-gray-700" />
            </a>
          </div>

          <div className="absolute bottom-0 left-0 right-0 bg-white p-6 transform transition-transform duration-300 group-hover:translate-y-0">
            <h3 className="text-xl font-bold text-gray-900 mb-2">
              {member.nom_prenom}
            </h3>
            <p className="text-orange-600 font-medium mb-2">
              {member.role}
            </p>
            <div className="h-1 w-12 bg-orange-500 rounded-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Equipe = () => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await api.get('/aydees/liste-equipe/');
        setMembers(response.data);
      } catch (error) {
        console.error('Erreur:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchMembers();
  }, []);

  if (loading) {
    return (
      <Layout>
        <div className="min-h-screen flex items-center justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500"></div>
        </div>
      </Layout>
    );
  }

  if (!members || members.length === 0) return null;

  return (
    <Layout>
      <PageBanner
        title="Notre Équipe"
        subtitle="Des experts passionnés qui donnent vie à vos projets"
      />

      <div className="bg-gradient-to-b from-orange-50 to-white">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-[linear-gradient(#FB923C10_1px,transparent_1px),linear-gradient(to_right,#FB923C10_1px,transparent_1px)] bg-[size:40px_40px]"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {members.map((member, index) => (
              <TeamMember
                key={member.id || index}
                member={member}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Equipe;