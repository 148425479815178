import React from 'react';
import {Target,  FileSearch, Briefcase, GraduationCap, Heart, ArrowRight, CheckCircle, BarChart} from 'lucide-react';
import Layout from '../components/Layout';
import PageBanner from '../components/Banner';

const ServiceCard = ({ icon: Icon, title, description, benefits }) => (
  <div className="bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow border border-orange-100 p-8">
    <div className="w-14 h-14 rounded-xl bg-orange-50 flex items-center justify-center mb-6">
      <Icon className="w-7 h-7 text-orange-500" />
    </div>
    <h3 className="text-xl font-bold text-gray-900 mb-4">{title}</h3>
    <p className="text-gray-600 mb-6">{description}</p>
    <ul className="space-y-3">
      {benefits.map((benefit, index) => (
        <li key={index} className="flex items-start space-x-3">
          <CheckCircle className="w-5 h-5 text-orange-500 flex-shrink-0 mt-0.5" />
          <span className="text-gray-600">{benefit}</span>
        </li>
      ))}
    </ul>
  </div>
);

const ProcessStep = ({ number, title, description }) => (
  <div className="relative">
    <div className="flex items-center space-x-4">
      <div className="w-12 h-12 bg-orange-500 text-white rounded-full flex items-center justify-center text-xl font-bold">
        {number}
      </div>
      <div>
        <h3 className="text-xl font-bold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

const RessourcesHumaines = () => {
  const services = [
    {
      icon: FileSearch,
      title: "Recrutement & Sélection",
      description: "Un processus complet de recrutement adapté à vos besoins",
      benefits: [
        "Analyse des besoins en recrutement",
        "Sourcing et présélection des candidats",
        "Évaluation des compétences",
        "Accompagnement à l'intégration"
      ]
    },
    {
      icon: GraduationCap,
      title: "Formation & Développement",
      description: "Des programmes de formation sur mesure pour vos équipes",
      benefits: [
        "Analyse des besoins en formation",
        "Conception de programmes personnalisés",
        "Formation continue",
        "Évaluation des acquis"
      ]
    },
    {
      icon: Target,
      title: "Gestion des Talents",
      description: "Optimisez le potentiel de vos collaborateurs",
      benefits: [
        "Identification des hauts potentiels",
        "Plans de développement individuels",
        "Gestion des carrières",
        "Programmes de mentorat"
      ]
    },
    {
      icon: BarChart,
      title: "Évaluation & Performance",
      description: "Systèmes d'évaluation efficaces et motivants",
      benefits: [
        "Définition des objectifs",
        "Entretiens d'évaluation",
        "Indicateurs de performance",
        "Plans de développement"
      ]
    },
    {
      icon: Heart,
      title: "Bien-être au Travail",
      description: "Créez un environnement de travail positif et productif",
      benefits: [
        "Enquêtes de satisfaction",
        "Programmes de bien-être",
        "Prévention des risques",
        "Amélioration continue"
      ]
    },
    {
      icon: Briefcase,
      title: "Conseil RH",
      description: "Expertise RH pour optimiser votre organisation",
      benefits: [
        "Audit des pratiques RH",
        "Optimisation des processus",
        "Conformité légale",
        "Accompagnement au changement"
      ]
    }
  ];

  const stats = [
    { number: "95%", label: "Taux de satisfaction client" },
    { number: "500+", label: "Recrutements réussis" },
    { number: "1000+", label: "Personnes formées" },
    { number: "50+", label: "Entreprises partenaires" }
  ];

  return (
    <Layout>
      <PageBanner
      title="Gestion des Ressources Humaines"
      subtitle=" Des solutions RH innovantes pour développer votre capital humain"
      />
      
      {/* Services RH */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Nos Services RH</h2>
            <p className="text-gray-600">
              Des solutions complètes pour répondre à tous vos besoins en ressources humaines
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>
        </div>
      </section>

      {/* Statistiques */}
      <section className="py-20 bg-gradient-to-r from-gray-900 to-gray-800 relative">
        <div className="absolute inset-0 bg-orange-500/10"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-4xl font-bold text-white mb-2">{stat.number}</div>
                <div className="text-gray-300">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Notre processus */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Notre Approche</h2>
            <p className="text-gray-600">
              Une méthodologie éprouvée pour des résultats durables
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <ProcessStep
              number="1"
              title="Analyse"
              description="Évaluation approfondie de vos besoins et objectifs RH"
            />
            <ProcessStep
              number="2"
              title="Stratégie"
              description="Élaboration d'une stratégie RH adaptée à vos enjeux"
            />
            <ProcessStep
              number="3"
              title="Mise en œuvre"
              description="Déploiement des solutions avec un accompagnement dédié"
            />
            <ProcessStep
              number="4"
              title="Suivi"
              description="Évaluation continue et optimisation des résultats"
            />
          </div>
        </div>
      </section>

      {/* CTA */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
            <div className="px-6 py-12 md:p-12 text-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                Prêt à optimiser votre gestion RH ?
              </h2>
              <p className="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
                Nos experts sont à votre disposition pour vous accompagner dans tous vos projets RH.
              </p>
              <div className="flex justify-center space-x-4">
                <button className="px-8 py-3 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition-all shadow-lg hover:shadow-xl flex items-center space-x-2">
                  <span>Demander un audit RH</span>
                  <ArrowRight className="w-5 h-5" />
                </button>
                {/* <button className="px-8 py-3 bg-gray-100 text-gray-800 rounded-lg font-semibold hover:bg-gray-200 transition-all">
                  En savoir plus
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RessourcesHumaines;