import React, { useEffect, useRef, useState } from 'react';
import { Linkedin, Twitter, ChevronLeft, ChevronRight } from 'lucide-react';
import api from './api';

const TeamMember = ({ member }) => {
  return (
    <div
      className="min-w-[300px] flex-shrink-0 mx-2 group"
      onClick={() => window.open(member.profile_link, '_blank')}
      style={{ cursor: 'pointer' }}
    >
      <div className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl">
        <div className="relative h-[311px] w-[298px] overflow-hidden">
          <img
            src={member.image}
            alt={member.nom_prenom}
            className="w-full h-full object-cover object-center"
            loading="eager"
          />

          <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

          <div className="absolute bottom-24 left-0 w-full flex justify-center space-x-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <a
              href={member.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation();
                window.open(member.linkedin, '_blank');
              }}
              className="p-3 bg-white rounded-full hover:bg-gray-100 transition-colors duration-300"
            >
              <Linkedin className="w-5 h-5 text-gray-700" />
            </a>
            <a
              href={member.twitter}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation();
                window.open(member.twitter, '_blank');
              }}
              className="p-3 bg-white rounded-full hover:bg-gray-100 transition-colors duration-300"
            >
              <Twitter className="w-5 h-5 text-gray-700" />
            </a>
          </div>

          <div className="absolute bottom-0 left-0 right-0 bg-white p-4">
            <h3 className="text-lg font-bold text-gray-900 mb-1">
              {member.nom_prenom}
            </h3>
            <p className="text-gray-600 text-sm font-medium">
              {member.role}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const TeamSection = () => {
  const scrollRef = useRef(null);
  const [isScrollPaused, setIsScrollPaused] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const response = await api.get('/aydees/liste-equipe/');
        setTeamMembers(response.data);
      } catch (error) {
        console.error('Erreur:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchTeam();
  }, []);

  const handleScroll = (direction) => {
    if (scrollRef.current) {
      setIsScrollPaused(true);
      const scrollAmount = direction === 'left' ? -300 : 300;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });

      setTimeout(() => {
        setIsScrollPaused(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let scrollInterval;

    if (!isScrollPaused) {
      scrollInterval = setInterval(() => {
        if (scrollContainer) {
          scrollContainer.scrollLeft += 1;
          if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
            scrollContainer.scrollLeft = 0;
          }
        }
      }, 30);
    }

    return () => {
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    };
  }, [isScrollPaused]);

  if (loading) {
    return (
      <div className="py-16 bg-gray-50 flex justify-center items-center min-h-[500px]">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  if (!teamMembers || teamMembers.length === 0) return null;

  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <p className="text-sm font-semibold text-gray-500 uppercase tracking-wide mb-3">
            NOTRE ÉQUIPE
          </p>
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Aydees Team
          </h2>
          <div className="h-1 w-20 bg-orange-500 mx-auto rounded-full mb-6"></div>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Une équipe d'experts passionnés à votre service
          </p>
        </div>

        <div className="relative px-4">
          <button
            onClick={() => handleScroll('left')}
            className="absolute left-0 top-1/2 -translate-y-1/2 z-20 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white transition-colors duration-300"
          >
            <ChevronLeft className="w-6 h-6 text-gray-700" />
          </button>
          <button
            onClick={() => handleScroll('right')}
            className="absolute right-0 top-1/2 -translate-y-1/2 z-20 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white transition-colors duration-300"
          >
            <ChevronRight className="w-6 h-6 text-gray-700" />
          </button>

          <div
            ref={scrollRef}
            className="flex overflow-hidden py-4 scroll-smooth"
          >
            {[...teamMembers, ...teamMembers, ...teamMembers].map((member, index) => (
              <TeamMember
                key={`${member.nom_prenom}-${index}`}
                member={member}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;