import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import PageBanner from '../components/Banner';
import api from '../components/api';
import { Calendar, Search } from 'lucide-react';

export const FormationsPage = () => {
    const navigate = useNavigate();
    const [formations, setFormations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [hoveredCard, setHoveredCard] = useState(null);

    useEffect(() => {
        const fetchFormations = async () => {
            try {
                const response = await api.get('/aydees/liste-formation/');
                setFormations(response.data);
            } catch (error) {
                console.error('Erreur:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchFormations();
    }, []);

    const calculateDuration = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    const filteredFormations = formations.filter(formation =>
        formation.titre.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return (
            <Layout>
                <div className="min-h-screen flex items-center justify-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500">
                        <div className="animate-pulse h-full w-full rounded-full bg-orange-200 opacity-75"></div>
                    </div>
                </div>
            </Layout>
        );
    }

    if (!formations || formations.length === 0) {
        return (
            <Layout>
                <PageBanner
                    title="Nos Formations"
                    subtitle="Développez vos compétences avec nos formations professionnelles"
                />
                <div className="py-16 bg-gradient-to-b from-gray-50 to-white">
                    <div className="max-w-2xl mx-auto text-center">
                        <h2 className="text-3xl font-bold text-gray-900 mb-4">
                            Aucune formation disponible
                        </h2>
                        <p className="text-gray-600 mb-8 text-lg">
                            Nous préparons de nouvelles formations passionnantes pour vous
                        </p>
                        <div className="relative animate-bounce w-16 h-16 mx-auto mb-8">
                            <Calendar className="w-full h-full text-orange-500" />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <PageBanner
                title="Nos Formations"
                subtitle="Développez vos compétences avec nos formations professionnelles"
            />
            <section className="py-16 bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="mb-12">
                        <div className="relative w-full max-w-md mx-auto">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Rechercher une formation..."
                                className="w-full pl-12 pr-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-all"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {filteredFormations.map((formation) => (
                            <div
                                key={formation.id}
                                className={`group bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:-translate-y-2 ${
                                    hoveredCard === formation.id ? 'ring-2 ring-orange-500' : ''
                                }`}
                                onMouseEnter={() => setHoveredCard(formation.id)}
                                onMouseLeave={() => setHoveredCard(null)}
                            >
                                <div className="relative h-64 overflow-hidden">
                                    <img
                                        src={formation.image}
                                        alt={formation.titre}
                                        className="w-full h-full object-cover transform transition-transform group-hover:scale-110"
                                    />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
                                </div>
                                
                                <div className="p-6">
                                    <h3 className="text-xl font-bold text-gray-900 mb-4">
                                        {formation.titre}
                                    </h3>
                                    
                                    <div className="space-y-3">
                                        <div className="flex items-center text-sm">
                                            <Calendar className="w-5 h-5 text-orange-500 mr-3" />
                                            <span className="text-gray-500">Début:</span>
                                            <span className="ml-auto font-medium">
                                                {new Date(formation.date_debut).toLocaleDateString()}
                                            </span>
                                        </div>

                                        <div className="flex items-center text-sm">
                                            <Calendar className="w-5 h-5 text-orange-500 mr-3" />
                                            <span className="text-gray-500">Fin:</span>
                                            <span className="ml-auto font-medium">
                                                {new Date(formation.date_fin).toLocaleDateString()}
                                            </span>
                                        </div>

                                        <div className="flex items-center text-sm">
                                            <span className="text-gray-500">Durée:</span>
                                            <span className="ml-auto font-medium">
                                                {calculateDuration(formation.date_debut, formation.date_fin)} jours
                                            </span>
                                        </div>

                                        <div className="pt-2 border-t">
                                            <div className="flex items-center justify-between">
                                                <span className="text-gray-500">Prix:</span>
                                                <span className="text-xl font-bold text-orange-500">
                                                    {formation.prix.toLocaleString()} FCFA
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 grid grid-cols-2 gap-4">
                                        <button
                                            onClick={() => navigate(`/inscription/${formation.id}/${formation.titre.toLowerCase().replace(/\s+/g, '-')}`)}
                                            className="bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 transition-colors"
                                        >
                                            S'inscrire
                                        </button>
                                        <button
                                            onClick={() => navigate(`/formation-detail/${formation.id}/${formation.titre.toLowerCase().replace(/\s+/g, '-')}`)}
                                            className="border-2 border-orange-500 text-orange-500 py-2 px-4 rounded-lg hover:bg-orange-50 transition-colors"
                                        >
                                            Détails
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {filteredFormations.length === 0 && (
                        <div className="text-center py-12">
                            <p className="text-lg text-gray-600">
                                Aucune formation ne correspond à votre recherche
                            </p>
                        </div>
                    )}
                </div>
            </section>
        </Layout>
    );
};

export default FormationsPage;