import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import PageBanner from '../components/Banner';
import api from '../components/api';
import { AlertCircle, CheckCircle2, XCircle, Clock } from 'lucide-react';

export const InscriptionForm = () => {
    const { id, titre } = useParams();
    const navigate = useNavigate();
    const [formation, setFormation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [inscriptionStatus, setInscriptionStatus] = useState(null); // 'success', 'error', 'pending'
    const [formData, setFormData] = useState({
        nom_complet: '',
        email: '',
        telephone: '',
        entreprise: ''
    });
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        const fetchFormation = async () => {
            try {
                const response = await api.get(`/aydees/formation-detail/${id}/`);
                setFormation(response.data);
            } catch (error) {
                setError("Impossible de charger les détails de la formation");
            } finally {
                setLoading(false);
            }
        };

        fetchFormation();
    }, [id]);

    const validateForm = () => {
        const errors = {};
        if (!formData.nom_complet.trim()) {
            errors.nom_complet = "Le nom complet est requis";
        }
        if (!formData.email.trim()) {
            errors.email = "L'email est requis";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            errors.email = "Format d'email invalide";
        }
        if (!formData.telephone.trim()) {
            errors.telephone = "Le téléphone est requis";
        } else if (!/^\d{8,}$/.test(formData.telephone.replace(/[\s-]/g, ''))) {
            errors.telephone = "Numéro de téléphone invalide";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setInscriptionStatus(null);

        if (!validateForm()) {
            return;
        }

        setSubmitting(true);

        try {
            const response = await api.post('/aydees/inscription/', {
                formation: id,
                ...formData
            });

            setInscriptionStatus('success');
            setTimeout(() => {
                navigate('/formations');
            }, 5000);
        } catch (error) {
            const errorMessage = error.response?.data?.error ||
                error.response?.data?.message ||
                "Une erreur est survenue lors de l'inscription";

            setError(errorMessage);
            setInscriptionStatus('error');
        } finally {
            setSubmitting(false);
        }
    };

    const StatusMessage = () => {
        switch (inscriptionStatus) {
            case 'success':
                return (
                    <div className="mb-6 p-4 bg-green-50 border border-green-200 rounded-lg">
                        <div className="flex items-center text-green-700 mb-2">
                            <CheckCircle2 className="w-5 h-5 mr-2" />
                            <span className="font-medium">Inscription enregistrée avec succès !</span>
                        </div>
                        <p className="text-green-600 text-sm ml-7">
                            Votre inscription est en cours de traitement. Redirection dans quelques secondes...
                        </p>
                    </div>
                );
            case 'error':
                return (
                    <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
                        <div className="flex items-center text-red-700">
                            <XCircle className="w-5 h-5 mr-2" />
                            <span>{error}</span>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    if (loading) {
        return (
            <Layout>
                <div className="min-h-screen flex items-center justify-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500"></div>
                </div>
            </Layout>
        );
    }

    const formattedTitle = titre ? titre.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : '';

    return (
        <Layout>
            <PageBanner
                title="Inscription à la formation"
                subtitle={formattedTitle}
            />
            <div className="max-w-2xl mx-auto px-4 py-12">
                <div className="bg-white rounded-lg shadow p-8">
                    {formation && formation.places_disponibles <= 0 && (
                        <div className="mb-6 p-4 bg-yellow-50 border border-yellow-200 rounded-lg flex items-center text-yellow-700">
                            <AlertCircle className="w-5 h-5 mr-2" />
                            Cette formation n'a plus de places disponibles
                        </div>
                    )}

                    <StatusMessage />

                    {formation && formation.places_disponibles > 0 && (
                        <>
                            <div className="flex items-center justify-between mb-6">
                                <p className="text-sm text-gray-600">
                                    Les champs marqués d'un * sont obligatoires
                                </p>
                                <p className="text-sm text-orange-600 font-medium">
                                    {formation.places_disponibles} place{formation.places_disponibles > 1 ? 's' : ''} disponible{formation.places_disponibles > 1 ? 's' : ''}
                                </p>
                            </div>

                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Nom complet *
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        value={formData.nom_complet}
                                        onChange={(e) => {
                                            setFormData({ ...formData, nom_complet: e.target.value });
                                            if (formErrors.nom_complet) {
                                                setFormErrors({ ...formErrors, nom_complet: null });
                                            }
                                        }}
                                        className={`w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-colors ${formErrors.nom_complet ? 'border-red-500' : 'border-gray-300'
                                            }`}
                                        disabled={submitting}
                                    />
                                    {formErrors.nom_complet && (
                                        <p className="mt-1 text-sm text-red-600">{formErrors.nom_complet}</p>
                                    )}
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Email *
                                    </label>
                                    <input
                                        type="email"
                                        required
                                        value={formData.email}
                                        onChange={(e) => {
                                            setFormData({ ...formData, email: e.target.value });
                                            if (formErrors.email) {
                                                setFormErrors({ ...formErrors, email: null });
                                            }
                                        }}
                                        className={`w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-colors ${formErrors.email ? 'border-red-500' : 'border-gray-300'
                                            }`}
                                        disabled={submitting}
                                    />
                                    {formErrors.email && (
                                        <p className="mt-1 text-sm text-red-600">{formErrors.email}</p>
                                    )}
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Téléphone *
                                    </label>
                                    <input
                                        type="tel"
                                        required
                                        value={formData.telephone}
                                        onChange={(e) => {
                                            setFormData({ ...formData, telephone: e.target.value });
                                            if (formErrors.telephone) {
                                                setFormErrors({ ...formErrors, telephone: null });
                                            }
                                        }}
                                        className={`w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-colors ${formErrors.telephone ? 'border-red-500' : 'border-gray-300'
                                            }`}
                                        disabled={submitting}
                                    />
                                    {formErrors.telephone && (
                                        <p className="mt-1 text-sm text-red-600">{formErrors.telephone}</p>
                                    )}
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Entreprise
                                    </label>
                                    <input
                                        type="text"
                                        value={formData.entreprise}
                                        onChange={(e) => setFormData({ ...formData, entreprise: e.target.value })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-colors"
                                        disabled={submitting}
                                    />
                                </div>

                                <div className="pt-4">
                                    <button
                                        type="submit"
                                        disabled={submitting || inscriptionStatus === 'success'}
                                        className="w-full bg-orange-500 text-white py-3 px-4 rounded-md hover:bg-orange-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed relative"
                                    >
                                        {submitting ? (
                                            <>
                                                <span className="opacity-0">Confirmer l'inscription</span>
                                                <div className="absolute inset-0 flex items-center justify-center">
                                                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                                                </div>
                                            </>
                                        ) : (
                                            "Confirmer l'inscription"
                                        )}
                                    </button>
                                </div>

                                <div className="pt-4">
                                    <button
                                        type="button"
                                        onClick={() => navigate(-1)}
                                        className="w-full text-gray-600 py-2 px-4 rounded-md hover:bg-gray-50 transition-colors border border-gray-300"
                                        disabled={submitting}
                                    >
                                        Retour
                                    </button>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default InscriptionForm;